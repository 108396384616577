export const User = {
  Auth: '/user/auth',
  GoogleAuth: '/user/googleauth',
  FacebookAuth: '/user/facebookauth',
  RefreshToken: '/user/refresh',
  Register: '/user/register',
  ForgotPasswordSendCode: '/user/forgotpwdsendcode',
  ForgotPasswordConfirmCode: '/user/forgotpwdconfirm',
  RevokeAccess: '/user/revoke',
  ConfirmEmail: '/user/confirmemail',
  ResendEmailConfirmationCode: '/user/resendconfirm',
  DecryptConfirmationToken: '/user/decryptconfirmationtoken',
  List: '/user/list',
  Remove: '/user/remove',
};

export const Assessment = {
  Create: '/assessment/create',
  List: '/assessment/list',
  Clone: '/assessment/clone',
  DisabledList: '/assessment/disabledlist',
  Details: '/assessment/details',
  Edit: '/assessment/edit',
  Disable: '/assessment/disable',
  Approve: '/assessment/approve',
  Unapprove: '/assessment/unapprove',
  Remove: '/assessment/remove',
  Enable: '/assessment/enable',
  Update: '/assessment/update',
  UpdateSettings: '/assessment/updatesettings',
};

export const Answer = {
  Save: '/answer/save',
  List: '/answer/list',
  Questions: '/answer/questions',
  Report: '/answer/report',
  Report2: '/answer/reportpage2',
}

export const Weights = {
  SelectList: '/weights/selectlist',
  List: '/weights/list',
  Save: '/weights/save',
  Disable: '/weights/disable',
  Remove: '/weights/remove',
  Enable: '/weights/enable',
  Upload: '/weights/upload',
}

export const HubSpot = {
  portalId: '7577245',
  formGuid: '4442bf3c-84bf-4f7c-b7fa-247a3223c451',
  APIKey: '0d3d625c-6ab5-490e-8513-1ea8f0c13f00',
}
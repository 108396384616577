/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import store, { persistor } from './redux/store/store';
import { Provider } from 'react-redux';
import axiosInterceptors from "./services/config/axiosInterceptors";
import { PersistGate } from 'redux-persist/integration/react';

import Alert from 'components/Alert/Alert';
import Loader from 'components/Loader/Loader';
import Toast from "components/Toast/Toast";
import InlineLoader from "components/Loader/InlineLoader";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

const AuthLayout = lazy(() => import("layouts/Auth.js"));
const HomeLayout = lazy(() => import("layouts/Home.js"));
const AssessmentLayout = lazy(() => import("layouts/Assessment.js"));

axiosInterceptors.interceptor(store);

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<InlineLoader />}>
        <Alert />
        <Loader />
        <Toast />
        <Router history={hist}>
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            <Route path="/home" component={HomeLayout} />
            <Route path="/a" component={AssessmentLayout} />
            <Redirect from="/" to="/auth" />
          </Switch>
        </Router>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
